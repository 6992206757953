:root {
  --p1: #181920;
  --p2: #1b1c24;
  --p3: #202129;
  --p4: #262833;
  --p5: #2d303b;
  --p6: #333641;
  --p7: #3c404e;
  --p8: #414453;
  --p9: #5a5d68;
  --p10: #9497a2;
  --p11: #dcdee8;


  --c-text-darkest: var(--p2);
  --c-text-darker: var(--p4);
  --c-text-dark: var(--p8);
  --c-text-sub2: var(--p9);
  --c-text-sub: var(--p10);
  --c-text-default: var(--p11);
  --c-text-bright: #ffffff;

  --bg-band: var(--p1);
  --bg-main: var(--p2);
  --bg-dark-4: var(--p3);
  --bg-dark-3: var(--p4);
  --bg-dark-2: var(--p5);
  --bg-dark-1: var(--p6);
  --bg-light-1: var(--p7);
  --bg-light-2: var(--p8);
  --bg-light-3: var(--p10);
  --bg-light-4: var(--p11);

  --border-dark-3: var(--p2);
  --border-dark-2: var(--p4);
  --border-dark-1: var(--p5);
  --border-light-1: var(--p6);
  --border-light-2: var(--p8);
  --border-light-3: var(--p9);

  --c-band-border: var(--border-dark-2);
  --c-voucher-border: var(--border-light-3);
  --c-tag-bg: #404040; /* TODO use color from palette? */
  --shadow: var(--p1);

  --c-empty-asset-bg: #20222b; /* TODO use color from palette? */
  --c-empty-asset-icon: var(--p6);

  --c-popup-overlay: #1d1f2880; /* rgba(29, 31, 40, 0.5); */
  --c-popup-bg: var(--bg-light-2);

  --header-bg: #2c79ac;
  --header-border: #255679;

  --c-highlight: #3090ce;
  --c-btn-default: #3090ce;
  --c-btn-hover: #32a4ed;

  --c-collection: #1592e6;
  --c-coll-row: #1a344b;
  --c-coll-row-hover: #1d3b55;

  --c-waitlist: #00ba56;
  --c-wait-row: #213a37;
  --c-wait-row-hover: #163c2e;

  --c-ignore: #e00053;
  --c-ignore-row: #31000a;
  --c-ignore-row-hover: #420012;

  --c-sub: #884089;
  --c-sub-row: #372b38;
  --c-sub-row-hover: #453145;

  --c-ea: #559fca;
  --c-preorder: #094a77;

  --c-bundles: #f06420;
  --c-bundles-live: #c44509;
  --c-vouchers: #caa50f;
  --c-vouchers-live: #a3850e; /* TODO unused */
  --c-giveaway: #22f2d6;
  --c-giveaway-live: #109986; /* TODO unused*/
  --c-miscdeal: #438bc9;
  --c-miscdeal-live: #245e91;

  --c-drm: #39845c;
  --c-expiry: #eb5555;
  --c-expired: #d72e2e;
  --c-dlc: #9b5de5; /* TODO alt: #a655b2 */
  --c-package: #00b4d8; /* TODO alts: #2b668f; #046eb2; */
  --c-mature: #e61b72;

  --c-success: #02ba55;
  --c-error: #d72e2e;
  --c-notice: #f8b100;
  --c-error-input-bg: #362933;

  --score-0: #d62205;
  --score-1: #de4603;
  --score-2: #e76c02;
  --score-3: #f09001;
  --score-4: #f8b100;
  --score-5: #e4ca09;
  --score-6: #a5c51e;
  --score-7: #75c22e;
  --score-8: #49bf3d;
  --score-9: #02ba55;
  --score-10: #02ba55;

  --mark-new: #ff5a2f;
  --mark-history: #ffb726;
  --mark-store: #3090ce;

  --c-tag-admin: #046eb2;
  --c-tag-mod: #04b208;
  --c-tag-official: #ffcc00;

  --c-settings-anchor: #27374a;

  /* TODO are these needed? */
  --c-active-menu-border: #046eb2;
  --c-active-menu-bg: #184f76;

  --c-navigator: #e91e63;
  --c-pilot: #f4d35e;
}
